import axios from "axios";
import { API_URL } from "../Config";
const Api = axios.create({
  baseURL: API_URL,
});

Api.interceptors.request.use(
  (config) => {
    // Add a header or do something before making the request
    return config;
  },
  (error) => {
    // Handle the error
    return Promise.reject(error);
  }
);

Api.interceptors.response.use(
  (response) => {
    // Do something with the response data
    return response;
  },
  (error) => {
    // Handle the error
    return Promise.reject(error);
  }
);

export default Api