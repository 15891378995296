import React from "react";
import { Nav, Navbar, Container } from "react-bootstrap";
import logoHome from "../assets/images/home.png";
import search from "../assets/images/search.png";
import mic from "../assets/images/mic.png";
const Header = () => {
  return (
    <header className="header">
      <Container>
        <Navbar expand="lg">
          <Navbar.Brand href="/">
            <img src={logoHome} alt="img" className="img-fluid" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto mt-2 mt-lg-0">
              <Nav.Link href="#">Home</Nav.Link>
              <Nav.Link className="active" href="#">
                ROOMS
              </Nav.Link>
              <Nav.Link href="#">GALLERY</Nav.Link>
              <Nav.Link href="#">DINING</Nav.Link>
              <Nav.Link href="#">MEETINGS</Nav.Link>
            </Nav>
            <div className="header-search my-2 my-lg-0">
              <img src={search} alt="img" />
              <input
                className="form-control mr-sm-2"
                type="search"
                placeholder="Search"
              />
              <img src={mic} alt="img" />
            </div>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </header>
  );
};

export default Header;
