import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import card from "../assets/images/card.png";
import bed from "../assets/images/bed.png";
import map from "../assets/images/map.png";
import id from "../assets/images/id.png";
import checked from "../assets/images/checked.png";
import axios from "axios";

const AfterVerify = () => {
  const location = useLocation();
  const uri = location.state.name;
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [dob, setDoB] = useState("");
  useEffect(() => {
    setTimeout(
      () =>
        axios
          .get(uri)
          .then((res) => {
            setName(res.data.data.claimsAttributes.legalName.claims.legalName);
            setAddress(res.data.data.claimsAttributes.address.claims.address);
            setDoB(
              res.data.data.claimsAttributes.dateOfBirth.claims.dateOfBirth
            );
          })
          .catch((err) => {
            console.log("err ", err);
          }),

      1000
    );
  }, []);

  return (
    <section className="">
      <Container>
        <Row>
          <Col lg="7" className="m-auto">
            <div className="mb-5">
              <h3 className="d-flex align-items-center justify-content-center">
                <img src={id} alt="img" />
                Your identity was verified{" "}
                <img src={checked} className="ms-3" alt="img" />
              </h3>
            </div>
            <div className="cover">
              <div className="cover-detail">
                <h4>Stellar Apt </h4>
                <hr />
                <div
                  className="d-flex align-items-center"
                  style={{ fontSize: "12px" }}
                >
                  <img
                    src={map}
                    className="img-fluid me-3"
                    alt="img"
                    width="15"
                  />{" "}
                  63 W 38th St, New York, NY 10018
                </div>
                <span className="room-qty">3 NIGHTS</span>
              </div>
            </div>
            <Row>
              <Col md="5">
                <h5 className="mb-4">My details</h5>
                <div className="d-flex" style={{ fontSize: "12px" }}>
                  <div>
                    <i className="fas fa-user me-4"></i>{" "}
                  </div>
                  <div style={{ fontSize: "12px", color: "#e0e0e0" }}>
                    <span
                      className=""
                      style={{ fontSize: "16px", color: " #fff" }}
                    >
                      {name}
                    </span>{" "}
                    <br />
                    {dob}
                    <br /> {address}
                    <br />
                    <span style={{ color: "#9C27B0" }}>Verified by ID</span>
                  </div>
                </div>
              </Col>
              <Col md="7">
                <h5 className="mb-4">Pay $675</h5>
                <div className="d-flex mb-3" style={{ fontSize: "12px" }}>
                  <div>
                    <img
                      src={bed}
                      alt="img"
                      className="img-fluid me-3"
                      width="15"
                    />{" "}
                  </div>
                  <div style={{ fontSize: "12px", color: "#e0e0e0" }}>
                    <span
                      className=""
                      style={{ fontSize: "16px", color: "#fff" }}
                    >
                      Stellar Apt{" "}
                    </span>{" "}
                    <br />3 Nights
                    <br />
                    <span style={{ color: "#9C27B0" }}>$225 per night</span>
                  </div>
                </div>
                <div className="d-flex" style={{ fontSize: "12px" }}>
                  <div>
                    <img
                      src={card}
                      alt="img"
                      className="img-fluid me-4"
                      width="15"
                    />{" "}
                  </div>
                  <form className="manual-detail">
                    <Row>
                      <div className="col-9 ">
                        <div className="form-group">
                          <label htmlFor="">Card Holder name</label>
                          <input type="text" name="" className="form-control" />
                        </div>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-9">
                        <div className="form-group">
                          <label htmlFor="">Card number</label>
                          <input type="text" name="" className="form-control" />
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="form-group">
                          <label htmlFor="">CVC</label>
                          <input type="text" name="" className="form-control" />
                        </div>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-3">
                        <div className="form-group">
                          <label htmlFor="">MM</label>
                          <input type="text" name="" className="form-control" />
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="form-group">
                          <label htmlFor="">YY</label>
                          <input type="text" name="" className="form-control" />
                        </div>
                      </div>
                    </Row>
                    <div>
                      <button type="button" className="custom-btn">
                        CONFIRM AND PAY
                      </button>
                    </div>
                  </form>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AfterVerify;
