import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./components/Home";
import Details from "./components/Details";
import VerifyId from "./components/VerifyId";
import AfterVerify from "./components/AfterVerify";

function App() {
  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route exect path="/" element={<Home />}></Route>
          <Route exect path="/details" element={<Details />}></Route>
          <Route exect path="/verifyIdentity" element={<VerifyId />}></Route>
          <Route exect path="/afterVerify" element={<AfterVerify />}></Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
