import React from "react";
import { Container, Row, Col, Carousel } from "react-bootstrap";
import roomDetail1 from "../assets/images/room-detail1.jpg";
import roomDetail2 from "../assets/images/room-detail2.jpg";
import roomDetail3 from "../assets/images/room-detail3.jpg";
import map from "../assets/images/map.png";
import bed from "../assets/images/bed.png";
import bathroom from "../assets/images/bathroom.png";
import arrow from "../assets/images/arrow.png";
import cross from "../assets/images/cross.png";
import agent from "../assets/images/agent.jpg";
import security from "../assets/images/security.png";
import { Link } from "react-router-dom";

const Details = () => {
  return (
    <>
      <section className="p-0">
        <Carousel>
          <Carousel.Item>
            <div
              className="room-img img-ht-500"
              style={{ backgroundImage: "url(" + roomDetail1 + ")" }}
            ></div>
            <Carousel.Caption>
              <div>
                <span className="custom-btn me-2">Popular Choice</span>{" "}
                <i className="fas fa-thumbs-up"></i> 92%
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <div
              className="room-img img-ht-500"
              style={{ backgroundImage: "url(" + roomDetail2 + ")" }}
            ></div>
            <Carousel.Caption>
              <div>
                <span className="custom-btn me-2">Popular Choice</span>{" "}
                <i className="fas fa-thumbs-up"></i> 92%
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <div
              className="room-img img-ht-500"
              style={{ backgroundImage: "url(" + roomDetail3 + ")" }}
            ></div>
            <Carousel.Caption>
              <div>
                <span className="custom-btn me-2">Popular Choice</span>{" "}
                <i className="fas fa-thumbs-up"></i> 92%
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </section>
      <section>
        <Container>
          <Row>
            <Col lg="7" className="m-auto">
              <div className="room-details">
                <div className="w-100">
                  <h4 className="my-3 ">
                    <span className="fa fa-chevron-left me-3"></span>Stellar
                    Apartment
                  </h4>
                  <div className="ms-md-4">
                    <div className="amount ms-1">$225</div>
                    <div className="ms-1">
                      <small>Average per night</small>
                    </div>
                    <ul className="address">
                      <li>
                        <img src={map} className="img-fluid" alt="img" /> New
                        York, NY 10018
                      </li>
                      <li>
                        <img src={bed} className="img-fluid" alt="img" /> 2 bed
                      </li>
                      <li>
                        <img src={bathroom} className="img-fluid" alt="img" /> 1
                        bathroom
                      </li>
                      <li>
                        <img src={arrow} className="img-fluid" alt="img" /> 1200
                        sq. ft
                      </li>
                      <li>
                        <img src={cross} className="img-fluid" alt="img" /> No
                        pets
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="verti-line"></div>
                <div className="w-100" style={{ lineHeight: 1 }}>
                  <div className="d-flex align-items-center mb-3">
                    <div className="user-img">
                      <img src={agent} alt="img" className="img-fluid" />
                    </div>
                    <div className="mx-3">
                      <p className="mb-0 d-inline">Polly Smith </p>
                      <div>
                        <span className="">
                          <img src={security} alt="img" />
                        </span>
                        <small style={{ color: "#8B8B8B" }}> Super Host</small>
                      </div>
                    </div>
                    <div>
                      <button type="button" className="custom-btn">
                        MESSAGE
                      </button>
                    </div>
                  </div>
                  <h6>$225 Average per night</h6>
                  <div className="booking-details">
                    <div className="check-in-out">
                      <div
                        className="w-50 py-2 px-3"
                        style={{ borderRight: "1px solid #431C60" }}
                      >
                        <label>Check-in</label>
                        <div className="">19/5/21</div>
                      </div>
                      <div className="w-50 py-2 px-3">
                        <label>Checkout</label>
                        <div className="">22/5/21</div>
                      </div>
                    </div>
                    <div className="guest">
                      <label>Guests</label>
                      <div className="">2 guests</div>
                      <span className="fa fa-chevron-down"></span>
                    </div>
                  </div>
                  <div>
                    <Link
                      to="/verifyIdentity"
                      className="custom-btn d-block"
                      style={{ margin: "15px 0" }}
                    >
                      RESERVE
                    </Link>
                  </div>
                  <p className="text-center">You won’t be charged yet</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Details;
