import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import room from "../assets/images/room.jpg";
import room2 from "../assets/images/room2.jpg";

const Home = () => {
  const rooms = [
    {
      name: "Baroque Hotel",
      price: 450.0,
      img: room,
    },
    {
      name: "Stellar Apt",
      price: 400.0,
      img: room2,
    },
    {
      name: "Azure Pier",
      price: 390.0,
      img: room,
    },
    {
      name: "The Onyx",
      price: 480.0,
      img: room2,
    },
  ];

  return (
    <section>
      <Container>
        <Row>
          <Col lg="9" className="m-auto">
            <h3>
              <span className="fas fa-chevron-left me-3"></span>Rooms and suites
            </h3>
            <div className="ms-md-4">
              <p className="ms-2">
                Hotel INC serves a daily breakfast buffet with international
                options and traditional Irish favorites. Broyage Bar & Bistro
                serves lunch, dinner, cocktails, and coffee al fresco.
              </p>
              <div className="room-grid">
                {rooms.map((item, i) => {
                  return (
                    <Link to="/details" className="room-block" key={i}>
                      <div
                        className="room-img img-ht-230 "
                        style={{ backgroundImage: `url( ${item.img})` }}
                      ></div>
                      <div className="room-name">
                        {item.name}
                        <div className="rating">
                          <span>
                            <i className="fas fa-star"></i>
                          </span>
                          <span>
                            <i className="fas fa-star"></i>
                          </span>
                          <span>
                            <i className="fas fa-star"></i>
                          </span>
                          <span>
                            <i className="fas fa-star"></i>
                          </span>
                          <span>
                            <i className="far fa-star"></i>
                          </span>
                        </div>
                      </div>
                      <div className="room-cost">${item.price}</div>
                    </Link>
                  );
                })}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Home;
