import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { SCRIPT_SRC, API_URL } from "../Config";
import { getConfig } from "../Services";

const VerifyId = () => {
  const navigate = useNavigate();
  const [codeChallengeData, setCodeChallengeData] = useState(null);

  useEffect(() => {
    const script = document.createElement("script");
    getConfig()
      .then((response) => {
        setCodeChallengeData(response.data.data);
        setTimeout(() => {
          script.src = SCRIPT_SRC;
          script.async = true;
          document.body.appendChild(script);
        }, 1000);
      })
      .catch((error) => {
        console.log("error", error);
      });
 
    return () => {
      if (document.body.contains(script)) {
        console.log("return")
        document.body.removeChild(script);
      }
    };
  }, []);

  window.funcName = function (param) {
    if (param) {
      const uri = `${API_URL}/claimidenitity/${param.data.arid}/${codeChallengeData.sessionId}`;
      navigate("/afterVerify", { state: { id: 1, name: uri } });
    }
  };

  return (
    <>
      <section>
        <Container>
          <Row>
            <Col lg="9" className=" m-auto">
              <div className="card-block text-center">
                <h3 className="">
                  To continue, we need to verify your identity
                </h3>
                <h6>We use ID by Mastercard to make sure you’re really you</h6>
                <div className="my-5 d-flex justify-content-center">
                  {codeChallengeData && (
                    <div className="jumbotron main">
                      <div
                        id="mids"
                        className="mids-dark mids-corner-radius-8px mids-cta-continue en-US"
                        callback_function="rpFunction"
                        {...codeChallengeData}
                      />
                    </div>
                  )}
                </div>
              </div>
            </Col>
            <Col lg="12">
              <div
                className="alert warning"
                id="failureId"
                style={{ display: "none" }}
              >
                <span
                  className="closebtn"
                  onClick="this.parentElement.style.display='none';"
                >
                  &times;
                </span>
                <strong>Warning!</strong>{" "}
                <span
                  id="failureMessageId"
                  style={{ wordWrap: "break-word" }}
                ></span>
              </div>
            </Col>
            <Col lg="12">
              <div
                className="alert success ml-4"
                id="successId"
                style={{ display: "none" }}
              >
                <span
                  className="closebtn"
                  onClick="this.parentElement.style.display='none';"
                >
                  &times;
                </span>
                <strong>Success!</strong>{" "}
                <span
                  id="successMessageId"
                  style={{ wordWrap: "break-word" }}
                ></span>
                <strong>Claim data!</strong>
                <span id="claim" style={{ wordWrap: "break-word" }}></span>
                <div
                  className="loader"
                  id="claim-loader"
                  style={{ display: "none" }}
                ></div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default VerifyId;
